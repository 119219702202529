<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 47 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.8234 15.3452C33.2381 14.7578 32.5427 14.2918 31.7769 13.9738C31.0111 13.6559 30.1901 13.4922 29.3609 13.4922C28.5318 13.4922 27.7108 13.6559 26.945 13.9738C26.1792 14.2918 25.4837 14.7578 24.8984 15.3452L24.0001 16.2552L23.1018 15.3452C22.5165 14.7578 21.821 14.2918 21.0552 13.9738C20.2894 13.6559 19.4684 13.4922 18.6393 13.4922C17.8101 13.4922 16.9891 13.6559 16.2233 13.9738C15.4575 14.2918 14.7621 14.7578 14.1768 15.3452C11.7034 17.8185 11.5518 21.9952 14.6668 25.1685L24.0001 34.5018L33.3334 25.1685C36.4484 21.9952 36.2968 17.8185 33.8234 15.3452Z"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.0013 16.2539L20.3496 19.9172C19.888 20.3827 19.6289 21.0117 19.6289 21.6672C19.6289 22.3228 19.888 22.9518 20.3496 23.4172V23.4172C20.815 23.8789 21.4441 24.1379 22.0996 24.1379C22.7552 24.1379 23.3842 23.8789 23.8496 23.4172L26.4863 20.8389C27.1416 20.1906 28.0261 19.8269 28.9479 19.8269C29.8698 19.8269 30.7543 20.1906 31.4096 20.8389L34.2096 23.6389"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M31.0013 27.5013L28.668 25.168"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.5013 31.0013L25.168 28.668"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
  
  <script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 33,
    },
    height: {
      type: [Number, String],
      default: 26,
    },
    iconColor: {
      type: String,
      default: "#059AA3",
    },
  },
};
</script>