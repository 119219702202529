<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 47 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30 13L34 17"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29 18L32 15"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M30.9992 20L20.6992 30.3C19.6992 31.3 18.1992 31.3 17.2992 30.3L16.6992 29.7C15.6992 28.7 15.6992 27.2 16.6992 26.3L26.9992 16"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21 22L25 26"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17 30L14 33"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26 15L32 21"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
  
  <script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 33,
    },
    height: {
      type: [Number, String],
      default: 26,
    },
    iconColor: {
      type: String,
      default: "#059AA3",
    },
  },
};
</script>