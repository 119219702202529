<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 47 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.875 7.625H28.125L33.75 20.75L22.5 24.5L11.25 20.75L16.875 7.625Z"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.5 24.5L16.875 7.625"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28.125 22.625L22.5 7.625"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.5 38.5625L16.875 41.375L17.8125 34.8125L14.0625 31.0625L19.6875 30.125L22.5 24.5L25.3125 30.125L30.9375 31.0625L27.1875 34.8125L28.125 41.375L22.5 38.5625Z"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

    <path
      d="M16.875 7.625H28.125L33.75 20.75L22.5 24.5L11.25 20.75L16.875 7.625Z"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.5 24.5L16.875 7.625"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28.125 22.625L22.5 7.625"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.5 38.5625L16.875 41.375L17.8125 34.8125L14.0625 31.0625L19.6875 30.125L22.5 24.5L25.3125 30.125L30.9375 31.0625L27.1875 34.8125L28.125 41.375L22.5 38.5625Z"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
  
  <script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#059AA3",
    },
  },
};
</script>