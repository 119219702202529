<template>
  <ModalsConfirm :show="sharingModalsComp">
    <div slot="actionQuestion" class="relative">
      <div class="
          absolute
          top-0
          right-0
          text-lg
          font-proximaSemibold
          cursor-pointer
        " @click="closeModals">
        ✖
      </div>
    </div>
    <div slot="actionText" class="flex flex-col justify-center items-center">
      <h1 class="w-full md:w-9/12 text-left text-xl font-proximaMedium">{{ $t('add_not_modals_title') }}</h1>
      <textarea v-model="note" placeholder="You can add notes for pet sitters, friends etc. " class="mt-2 p-2 w-full md:w-9/12 h-44 border-2 border-primary rounded-md"></textarea>
    </div>
    <BorderedButton slot="leftBtn" class="md:mr-1" :title="$t('global_cancel')" borderColor="border-mediumGrey" textCl="text-darkGrey" paddingX="px-8" width="w-full" @click.native="closeModals" />
    <SubmitButton slot="rightBtn" class="md:ml-1" :title="$t('global_confirm')" background="bg-success" paddingX="px-8" width="w-full" @click.native="openConfirmIdModals" />
  </ModalsConfirm>
</template>

<script>
import ModalsConfirm from "../modals/ModalsConfirm.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";

export default {
  components: {
    ModalsConfirm,
    SubmitButton,
    BorderedButton,
  },
  data() {
    return { note: "" };
  },
  computed: {
    sharingModalsComp() {
      return this.$store.state.addNoteModalsState;
    },
  },
  methods: {
    closeModals() {
      this.$store.commit("addNoteModals", false);
    },
    openConfirmIdModals() {
      this.$emit("note", this.note);
      this.$store.commit("addNoteModals", false);
      console.log(this.note);
    }
  },
};
</script>

<style>

</style>