<template>
  <ModalsConfirm :show="sharingModalsComp">
    <div slot="actionQuestion" class="text-xl">
      <img
        class="mx-auto"
        src="@/assets/images/emailphone-icon.png"
        alt="emailphone-icon"
      />
    </div>
    <div slot="actionText">
      <h3 class="text-xl font-proximaMedium">
        Share via email or phone number
      </h3>
      <FormError :errors="getError" />
      <FormError :errors="errorForm" />
      <input
        type="text"
        class="w-full md:w-8/12 rounded-md px-3 py-3 my-1 text-sm border border-primary"
        v-model="emailPhone"
        placeholder="Email address / phone number"
      />
    </div>
    <BorderedButton
      slot="leftBtn"
      class="md:mr-1"
      :title="$t('global_cancel')"
      borderColor="border-mediumGrey"
      textCl="text-darkGrey"
      paddingX="px-8"
      width="w-full"
      @click.native="closeModals"
    />
    <SubmitButton
      slot="rightBtn"
      class="md:ml-1"
      :title="$t('global_confirm')"
      background="bg-success"
      paddingX="px-8"
      width="w-full"
      @click.native="submitSend"
    />
  </ModalsConfirm>
</template>

<script>
import ModalsConfirm from "../modals/ModalsConfirm.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";
import FormError from "../warning/FormError.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { validEmail, validPhone } from "../../methods/petMethods";

export default {
  props: ["petID", "shareable_objects", "notes", "expiration_date"],
  components: {
    ModalsConfirm,
    SubmitButton,
    BorderedButton,
    FormError,
  },
  data() {
    return { emailPhone: "" };
  },
  computed: {
    ...mapGetters({
      getError: "sharing/getError",
      sharingModalsComp: "sharing/getSharingEmailPhoneModals",
    }),
    sharingModalsComp() {
      return this.$store.state.sharing.sharingEmailPhoneModalsState;
    },

    errorForm() {
      return this.$store.state.errorForm;
    },

    errorForm() {
      return this.$store.state.errorForm;
    },
  },
  created() {
    this.$store.commit("errorForm", "");
  },

  methods: {
    ...mapActions({
      shareByEmail: "sharing/shareByEmail",
      sharingEmailPhoneModals: "sharing/sharingEmailPhoneModals",
    }),
    closeModals() {
      this.sharingEmailPhoneModals(false);
      this.$store.commit("errorForm", "");
      //this.$store.commit("sharing.sharingEmailPhoneModals", false);
    },
    validate() {
      const errors = {};

      if (this.emailPhone == "")
        errors.emailPhone =
          "Email or Phone number " + this.$t("global_is_required");
      if (this.expiration_date == "")
        errors.expiration_date = "Date " + this.$t("global_is_required");

      return Object.keys(errors).length === 0 ? null : errors;
    },
    async submitSend() {
      alert("me 1");
      const data = {
        newJoinerEmailAddress: this.emailPhone,
        //newJoinerphoneNo: this.emailPhone,
        granted_by: localStorage.getItem("email"),
        petID: this.petID,
        shareable_objects: this.shareable_objects,
        notes: this.notes,
        expiration_date:
          this.expiration_date == null
            ? null
            : moment(this.expiration_date).format("DD/MM/YYYY"),
      };

      const errors = this.validate();
      const er = errors || {};
      this.$store.commit("errorForm", er);
      if (errors) return;

      this.shareByEmail(data);
    },
    validate() {
      const errors = {};

      if (this.emailPhone == "")
        errors.emailPhone =
          "Email or Phone number " + this.$t("global_is_required");
      if (this.expiration_date == "")
        errors.expiration_date = "Date " + this.$t("global_is_required");

      return Object.keys(errors).length === 0 ? null : errors;
    },
    async submitSend() {
      const data = {
        //newJoinerEmailAddress: this.emailPhone,
        //newJoinerphoneNo: this.emailPhone,
        granted_by: localStorage.getItem("email"),
        petID: this.petID,
        shareable_objects: this.shareable_objects,
        notes: this.notes,
        expiration_date: this.expiration_date
          ? moment(this.expiration_date).format("DD/MM/YYYY")
          : null,
      };

      /* check if is email then email or phone number else error  */
      if (validEmail(this.emailPhone)) {
        data.newJoinerEmailAddress = this.emailPhone;
        this.shareByEmail(data);
      } else if (validPhone(this.emailPhone)) {
        data.newJoinerphoneNo = this.emailPhone;
        this.shareByEmail(data);
      } else {
        this.$store.commit(
          "errorForm",
          "Valid Email Address or Phone Number required."
        );
      }

      /*  const errors = this.validate();
      const er = errors || {};
      this.$store.commit("errorForm", er);
      if (errors) return; */
    },
  },
};
</script>

<style></style>
