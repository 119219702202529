<template>
  <div>
    <Transition name="modal">
      <div v-if="show" class="modal-mask">
        <div class="modal-wrapper">
          <div
            class="modal-container md:w-11/12 lg:w-9/12 flex flex-col justify-center items-center"
          >
            <div
              class="w-full font-proximaMedium flex flex-col md:flex-row items-start justify-between relative"
            >
              <div
                class="absolute top-0 right-0 text-lg font-proximaSemibold cursor-pointer"
                @click="closeModalsharing"
              >
                ✖
              </div>
              <div class="w-full md:w-6/12">
                <div class="flex items-center">
                  <img
                    src="@/assets/images/share-icon.png"
                    class="h-10"
                    alt="share-icon"
                  />
                  <h1 class="ml-2 text-2xl text-subheaderText font-bold">
                    Share Pet Record
                  </h1>
                </div>
                <div class="w-full">
                  <div
                    class="w-max mt-8 mx-auto rounded-full border border-primary"
                  >
                    <img
                      style="height: 102px; width: 102px; border-radius: 100px"
                      class="p-1"
                      :src="pet.profile_picture"
                      alt="pet_icon"
                    />
                  </div>
                  <FormError :errors="errorForm" />
                  <PetRecordEach
                    v-for="(setting, i) in settings"
                    :key="i"
                    :setting="setting"
                    @settingVal="settingVal"
                  />

                  <SubmitButton
                    v-if="valideButton1 && valideButton2"
                    class="font-proximaLight"
                    :title="$t('share_pet_record_button')"
                    background="bg-primary"
                    paddingX="px-8"
                    width="w-full"
                    @click.native="submitAddSharingRule"
                  />
                  <SubmitButton
                    v-else
                    class="font-proximaLight cursor-not-allowed"
                    :title="$t('share_pet_record_button')"
                    background="bg-lightGrey"
                    paddingX="px-8"
                    width="w-full"
                  />
                </div>
              </div>
              <div
                class="w-full my-12 border border-mediumGrayn md:hidden"
              ></div>
              <div class="w-full md:w-5/12">
                <div>
                  <h3 class="text-left text-lg font-bold">Share Via</h3>
                  <IconButton
                    class="font-proximaLight mt-4"
                    title="Link"
                    background="bg-orange"
                    paddingX="px-8"
                    width="w-full"
                    component="LinkIcon"
                    iconColor="#fff"
                    @click.native="openLink"
                  />

                  <IconButton
                    class="font-proximaLight mt-4"
                    :title="$t('share_pet_record_generate_qr_code')"
                    background="bg-orange"
                    paddingX="px-8"
                    width="w-full"
                    component="BarcodeIcon"
                    iconColor="#fff"
                    @click.native="openBarcodeModals"
                  />
                  <SubmitButton
                    class="font-proximaLight"
                    :title="$t('share_pet_record_contact_list')"
                    background="bg-primary"
                    paddingX="px-8"
                    marginT="mt-4"
                    width="w-full"
                    @click.native="bulkShareModals = true"
                  />
                  <SubmitButton
                    class="font-proximaLight"
                    :title="$t('share_pet_record_email_phone')"
                    background="bg-primary"
                    paddingX="px-8"
                    marginT="mt-4"
                    width="w-full"
                    @click.native="openEmailModals"
                  />
                </div>
                <div class="mt-4 mb-8 w-full flex flex-col justify-center">
                  <h3 class="text-left text-xl font-proximaMedium">
                    Valid Until
                  </h3>
                  <datepicker
                    v-model="expiration_date"
                    @selected="getExpDate"
                    :inline="true"
                  ></datepicker>
                </div>
                <IconButton
                  :title="$t('global_add_notes')"
                  borderColor="border-orange"
                  textCl="text-orange"
                  paddingX="px-8"
                  width="w-full"
                  component="ClipboardCheckIcon"
                  iconColor="#F8961F"
                  @click.native="openAddNoteModals"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <BarcodeModals :codeQR="codeQR" />
    <EmailPhoneModals
      :petID="this.pet.petID"
      :shareable_objects="this.settingAdd"
      :notes="this.note"
      :expiration_date="this.expiration_date"
    />
    <AddNoteModals :diary="false" @note="getNote" />
    <ModalsConfirm :show="bulkShareModals" @close="bulkShareModals = false">
      <h1 slot="actionQuestion" class="text-2xl">
        {{ $t("pet_record_overview_bulk_share_records") }}
      </h1>
      <div
        slot="actionText"
        class="flex flex-col items-center justify-center text-sm font-proximaLight"
      >
        <span class="my-2"
          >Share all pet records at once, with specific contacts.
        </span>
        <div class="h-52 w-full md:w-9/12 overflow-scroll overflow-x-hidden">
          <div
            class="my-2 flex items-center justify-between"
            v-for="(contact, i) in contacts"
            :key="i"
          >
            <div class="flex items-center">
              <img
                class="h-6"
                src="@/assets/images/profilepic-icon.png"
                alt="profilepic-icon"
              />
              <span class="ml-2">{{ contact.contactEmailAddress }}</span>
            </div>
            <RadioOnoffBorder
              :val="contact"
              :checked="contact.checked"
              :checkAll="checkAll"
              @inputVal="inputVal"
            />
          </div>
        </div>
        <div class="w-10/12 md:w-9/12 mt-4 flex items-center justify-end">
          <!--  <label class="cont">
            <input type="checkbox" @change="checkAllFunc($event)" />
            <span class="checkmark"></span>
          </label>
          <span class="font-proximaLight text-md">Select all</span> -->
        </div>
      </div>
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        :title="$t('global_cancel')"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="closeModals"
      />
      <SubmitButton
        slot="rightBtn"
        class="md:ml-1"
        :title="$t('global_confirm')"
        background="bg-success"
        paddingX="px-8"
        width="w-full"
        @click.native="submitContactModal"
      />
    </ModalsConfirm>
  </div>
</template>

<script>
import PetRecordEach from "./PetRecordEach.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import IconButton from "../buttons/IconButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";
import Datepicker from "vuejs-datepicker";
import BarcodeModals from "./BarcodeModals.vue";
import EmailPhoneModals from "./EmailPhoneModals.vue";
import AddNoteModals from "./AddNoteModals.vue";
import ModalsConfirm from "../modals/ModalsConfirm.vue";

import { mapActions, mapGetters } from "vuex";
import FormError from "../warning/FormError.vue";
import RadioOnoffBorder from "../forms/RadioOnoffBorder.vue";
import moment from "moment";

export default {
  props: {
    show: Boolean,
    settingInitAdd: Array,
  },
  components: {
    PetRecordEach,
    SubmitButton,
    BorderedButton,
    IconButton,
    Datepicker,
    BarcodeModals,
    EmailPhoneModals,
    ModalsConfirm,
    AddNoteModals,
    FormError,
    RadioOnoffBorder,
  },

  data() {
    return {
      pet: {},
      bulkShareModals: false,
      preference: [],
      settingAdd: [],
      settingInitAdd_: this.settingInitAdd,
      dob: null,
      expiration_date: null,
      note: "",
      checkAll: false,
      contactsList: [],
      valideButton1: false,
      valideButton2: true,
      showTooltipLink: false,
    };
  },
  computed: {
    ...mapGetters({
      codeQR: "sharing/getCodeQR",

      contacts: "contact/getContacts",
    }),

    errorForm() {
      return this.$store.state.errorForm;
    },
    settings() {
      return [
        {
          id: "1",
          name: "Photo Gallery",
          checked: this.settingInitAdd.includes(Number("1")) ? true : false,
        },
        {
          id: "2",
          name: this.$t("pet_dropdown_menu_care_routine"),
          checked: this.settingInitAdd.includes(Number("2")) ? true : false,
        },
        /*  {
          id: "3",
          name: this.$t("pet_dropdown_menu_treatment_plan"),
          checked: this.settingInitAdd.includes(Number("3")) ? true : false,
        }, */
        {
          id: "4",
          name: this.$t("pet_dropdown_menu_symptom_tracker"),
          checked: this.settingInitAdd.includes(Number("4")) ? true : false,
        },
        {
          id: "5",
          name: this.$t("pet_record_breeding_status"),
          checked: this.settingInitAdd.includes(Number("5")) ? true : false,
        },
        /* {
          id: "6",
          name: "Blood Donor Status",
          checked: this.settingInitAdd.includes(Number("6")) ? true : false,
        }, */
        {
          id: "7",
          name: "Favourite Services",
          checked: this.settingInitAdd.includes(Number("7")) ? true : false,
        },
        {
          id: "8",
          name: "Medical History",
          checked: this.settingInitAdd.includes(Number("8")) ? true : false,
        },
      ];
    },
  },
  created() {
    console.log("settings : ", this.settings);
    this.$store.commit("errorForm", "");
    var retrievedObject = localStorage.getItem("pet");
    this.pet = JSON.parse(retrievedObject);

    this.preference = localStorage.getItem("sharingRulesPreferences");
    this.settingAdd = JSON.parse(this.preference);
  },

  mounted() {
    console.log("settings : ", this.settings);
    this.$store.commit("errorForm", "");
    if (localStorage.getItem("sharingRulesPreferences")) {
      this.preference = localStorage.getItem("sharingRulesPreferences");
      console.log("-------mounted---", this.preference);

      this.settingAdd = JSON.parse(this.preference);
      console.log("-------mounted---");
      console.log("8888 mt", this.settingInitAdd);
      //this.settingAdd = [];
    }
    this.expiration_date = null;

    const userEmail = localStorage.getItem("email");
    if (userEmail) {
      this.getContacts(userEmail);
    }
  },
  methods: {
    ...mapActions({
      shareByEmail: "sharing/shareByEmail",
      addSharingRuleQR: "sharing/addSharingRuleQR",
      addSharingRuleURL: "sharing/addSharingRuleURL",
      userSharingPreferences: "sharing/userSharingPreferences",
      sharingEmailPhoneModals: "sharing/sharingEmailPhoneModals",
      getContacts: "contact/getContacts",
    }),

    settingVal(val, checked) {
      if (checked) {
        this.settingAdd = [...this.settingAdd, Number(val)];
        console.log("add : ", this.settingAdd);
      } else {
        this.settingAdd = this.settingAdd.filter((p) => p !== Number(val));
        console.log("remove : ", val, this.settingAdd);
      }

      this.valideButton1 = this.settingAdd.length > 0 ? true : false;

      console.log(
        "valideButton1",
        this.valideButton1,
        this.preference.length,
        this.settingAdd
      );
    },
    closeModals() {
      this.$store.commit("errorForm", "");
      this.bulkShareModals = false;
    },
    closeModalsharing() {
      this.$store.commit("errorForm", "");
      this.settingAdd = [];
      this.expiration_date = null;
      this.$emit("close");
    },
    submitContactModal() {
      this.bulkShareModals = false;
    },
    getExpDate(a) {
      console.log(moment(a).format("DD/MM/YYYY"));
      this.expiration_date = moment(a).format("DD/MM/YYYY");
      //this.valideButton2 = this.expiration_date != null ? true : false;
    },
    async copyUrl(data) {
      try {
        await navigator.clipboard.writeText(data);

        this.showTooltipLink = true;

        // Hide the tooltip after a short delay (adjust as needed)
        setTimeout(() => {
          this.showTooltipLink = false;
        }, 2000);
      } catch (e) {}
    },
    async openLink() {
      if (localStorage.getItem("sharingRulesPreferences")) {
        var p = localStorage.getItem("sharingRulesPreferences");
        var pp = JSON.parse(p);
        this.settingAdd = [...this.settingAdd, ...pp];
        console.log(this.settingAdd, this.settingAdd.length);
      }

      if (this.settingAdd.length == 0) {
        this.$store.commit("errorForm", "Sharing permissions are required.");
      } else {
        this.$store.commit("errorForm", "");
        let uniquesettingAdd = [...new Set(this.settingAdd)];
        const data = {
          granted_by: localStorage.getItem("email"),
          petID: this.pet.petID,
          shareable_objects: uniquesettingAdd,
          notes: this.note,
        };
        console.log(data);
        this.expiration_date != null
          ? (data.expiration_date = moment(this.expiration_date).format(
              "DD/MM/YYYY"
            ))
          : null;

        await this.addSharingRuleURL(data).then((url) => {
          this.copyUrl(url);
          console.log(url);
          this.closeModalsharing();
        });
      }

      //this.$store.commit("sharingBarcodeModals", true);
    },
    openBarcodeModals() {
      if (localStorage.getItem("sharingRulesPreferences")) {
        var p = localStorage.getItem("sharingRulesPreferences");
        var pp = JSON.parse(p);
        this.settingAdd = [...this.settingAdd, ...pp];
      }
      if (this.settingAdd.length == 0) {
        this.$store.commit("errorForm", "Sharing permissions are required.");
      } else {
        this.$store.commit("errorForm", "");

        if (this.expiration_date == null) {
          const data = {
            granted_by: localStorage.getItem("email"),
            petID: this.pet.petID,
            shareable_objects: this.settingAdd,
            notes: this.note,
          };
          this.addSharingRuleQR(data);
          this.$store.commit("sharingBarcodeModals", true);
        } else {
          const dataa = {
            granted_by: localStorage.getItem("email"),
            petID: this.pet.petID,
            shareable_objects: this.settingAdd,
            notes: this.note,
            expiration_date: moment(this.expiration_date).format("DD/MM/YYYY"),
          };
          console.log(dataa);
          this.addSharingRuleQR(dataa);
          this.$store.commit("sharingBarcodeModals", true);
          this.closeModalsharing();
        }
      }
    },
    openEmailModals() {
      if (localStorage.getItem("sharingRulesPreferences")) {
        var p = localStorage.getItem("sharingRulesPreferences");
        var pp = JSON.parse(p);
        this.settingAdd = [...this.settingAdd, ...pp];
      }

      if (this.settingAdd.length == 0) {
        this.$store.commit("errorForm", "Sharing permissions are required.");
      } else {
        /* else if (this.expiration_date == null) {
        this.$store.commit("errorForm", "Date is required.");
      } */
        this.$store.commit("errorForm", "");
        this.sharingEmailPhoneModals(true);
      }
    },
    openAddNoteModals() {
      this.$store.commit("addNoteModals", true);
    },
    getNote(note) {
      this.note = note;
    },

    async submitAddSharingRule() {
      /* FIXME: preference + add settings */
      if (this.settingAdd.length == 0) {
        this.$store.commit("errorForm", "Sharing permissions are required.");
      } else {
        this.$store.commit("errorForm", "");

        /*    const vals = this.contactsList.filter((p) => p.checked == true);
        vals.map((v) => {
          console.log(v);

          const data = {
            newJoinerEmailAddress: v.contactEmailAddress,
            //newJoinerphoneNo: this.emailPhone,
            granted_by: localStorage.getItem("email"),
            petID: this.pet.petID,
            shareable_objects: this.settingAdd,
            notes: this.note,
            expiration_date:
              this.expiration_date == null
                ? null
                : moment(this.expiration_date).format("DD/MM/YYYY"),
          };
          this.shareByEmail(data);
          
        });
 */
        let uniquesettingAdd = [...new Set(this.settingAdd)];
        const req = {
          userEmailAddress: localStorage.getItem("email"),
          shareable_objects: uniquesettingAdd,
        };
        console.log(req);
        this.userSharingPreferences(req);
      }
    },
    checkAllFunc(e) {
      // console.log(e.originalTarget.checked);
      this.checkAll = e.originalTarget.checked;
    },
    inputVal(contact, checked) {
      contact.checked = checked;
      console.log(contact);

      const contacts = [...this.contacts];
      const index = contacts.indexOf(contact);
      contacts[index] = { ...contact };
      this.contactsList = contacts;
    },
  },
};
</script>

<style lang="scss">
.vdp-datepicker__calendar {
  border: none !important;
}

.vdp-datepicker__calendar {
  margin: auto !important;
}

.vdp-datepicker__calendar .cell.selected {
  background: #0ac0cb !important;
  color: #fafafa !important;
  border-radius: 100% !important;
}

.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover {
  border-radius: 100% !important;
  border: 1px solid #0ac0cb !important;
}

.modal-mask {
  position: absolute;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  // width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
