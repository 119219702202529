<template>
  <div class="flex flex-col justify-center items-center">
    <div class="w-full flex justify-between items-start">
      <div class="mt-5 ml-2 lg:w-full md:w-8/12 md:block hidden">
        <img
          class="rounded-full w-32 h-32"
          :src="pet.profile_picture"
          :alt="pet.profile_picture"
        />
      </div>
      <HeaderGlobal
        :titlemenu1="this.titlemenu1"
        :titlemenu2="this.titlemenu2"
        :to2="'/sharingsetting'"
        :titlemenu3="this.titlemenu3"
      />
    </div>
    <SharingContentsVue :settingInitAdd="sharingRulesPreferences" />
  </div>
</template>

<script>
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import HeaderGlobal from "../../header/HeaderGlobal.vue";
import AddIcon from "../../icons/AddIcon.vue";
import RadioOnoff from "../../forms/RadioOnoff.vue";
import DiaryIcon from "../../icons/DiaryIcon.vue";
import TreatmentIcon from "../../icons/TreatmentIcon.vue";
import TrainingIcon from "../../icons/TrainingIcon.vue";
import PhotoGalleryIcon from "../../icons/PhotoGalleryIcon.vue";
import FavouriteServicesIcon from "../../icons/FavouriteServicesIcon.vue";
import CareroutineIcon from "../../icons/CareroutineIcon.vue";
import BloodDonorStatusIcon from "../../icons/BloodDonorStatusIcon.vue";
import BreederStatusIcon from "../../icons/BreederStatusIcon.vue";
import MedicationIcon from "../../icons/MedicationIcon.vue";
import SharingContentsVue from "./SharingContents.vue";
import { nameOfPet } from "../../../methods/petMethods";
// import SharePetRecordModals from "./SharePetRecordModals.vue";
import { mapActions, mapGetters } from "vuex";
import {
  enableSharingCareBlog,
  stopSharingCareBlog,
} from "../../../services/sharingService";
import moment from "moment";
export default {
  components: {
    HeaderGlobal,
    AddIcon,
    RadioOnoff,
    DiaryIcon,
    TreatmentIcon,
    TrainingIcon,
    PhotoGalleryIcon,
    CareroutineIcon,
    MedicationIcon,
    // SharePetRecordModals,
    FavouriteServicesIcon,
    BloodDonorStatusIcon,
    BreederStatusIcon,
    ModalsConfirm,
    SharingContentsVue,
  },
  data() {
    return {
      pet: {},
      showAddSetting: false,
      titlemenu1: "",
      titlemenu2: this.$t("pet_dropdown_menu_sharing_setting"),
      titlemenu3: this.$t("pet_dropdown_menu_sharing_setting_new_rule"),
      contactsList: [],
      showTooltipLink: false,
      link: null,
      barcodeModals: false,
    };
  },
  computed: {
    ...mapGetters({
      sharingRules: "sharing/getSharingRules",
      sharingRulesPreferences: "sharing/getSharingRulesPreferences",
    }),
  },
  created() {
    var retrievedObject = localStorage.getItem("pet");
    this.pet = JSON.parse(retrievedObject);
    this.getuserSharingPreferences(localStorage.getItem("email"));
  },
  mounted() {
    var retrievedObject = localStorage.getItem("pet");
    this.pet = JSON.parse(retrievedObject);

    if (this.pet) {
      this.titlemenu1 =
        nameOfPet(this.pet.species) + " Record: " + this.pet.callName;

      this.getSharingRules(this.pet.petID);
    }
  },
  methods: {
    ...mapActions({
      getSharingRules: "sharing/getSharingRules",
      updateSharingRule: "sharing/updateSharingRule",
      getuserSharingPreferences: "sharing/getuserSharingPreferences",
    }),
    async copyUrl(data) {
      try {
        await navigator.clipboard.writeText(data);
        console.log(data);
        this.showTooltipLink = true;
        this.link = data;

        // Hide the tooltip after a short delay (adjust as needed)
        setTimeout(() => {
          this.showTooltipLink = false;
        }, 2000);
      } catch (e) {}
    },
    getcontactsList(cont) {
      this.contactsList = cont;
    },
    getDateExp: function (date) {
      return date ? date.substring(0, 10) : "No expiry";
      //moment(date, "DD/MM/YYYY").format("DD/MM/YYYY")
      //console.log(typeof date);
      //return date ? date : "no expiry";
    },
    orderedList(sharingRules) {
      // Sort the list based on the createdAt property
      return sharingRules.slice().sort((a, b) => {
        // Assuming createdAt is a string in 'YYYY-MM-DDTHH:mm:ss' format
        return new Date(b.date_created) - new Date(a.date_created);
      });
    },
    getIconShare(data) {
      if (data.qrcode) {
        return "@/assets/images/barcode-sm.png";
      } else if (data.url) {
        return "@/assets/images/shareicon-sm.png";
      } else {
        return "";
      }
    },
    whichIcon(so) {
      if (so == 1) {
        return "PhotoGalleryIcon";
      } else if (so == 2) {
        return "CareroutineIcon";
      } else if (so == 3) {
        return "TreatmentIcon";
      } else if (so == 4) {
        return "DiaryIcon";
      } else if (so == 5) {
        return "BreederStatusIcon";
      } else if (so == 6) {
        return "BloodDonorStatusIcon";
      } else if (so == 7) {
        return "FavouriteServicesIcon";
      } else if (so == 8) {
        return "MedicationIcon";
      } /* else {
        return "DiaryIcon";
      } */
    },
    async inputVal(val, checked) {
      val["is_enable"] = checked;
      this.updateSharingRule(val);
    },
  },
};
</script>

<style>
.tooltip {
  position: absolute;
  background-color: #354e57;
  color: #fff;
  padding: 1px;
  border-radius: 4px;
  /*  z-index: 1000; */
  /* You can adjust the positioning styles as needed */
  /*  top: 50px;
  left: 50%; */
  /* transform: translateX(-50%); */
}
</style>
