<template>
  <div class="mt-2 mb-6 md:my-0 flex flex-col md:flex-row items-start relative">
    <!-- <SectionLeft class="hidden md:block" :pet="this.$route.params.data"/> -->
    <div class="h-full md:h-screen w-full px-4">
      <SharingSection :pet="this.$route.params.data" />
    </div>
    <DataserviceLogo />
    <NavMenuSecondary page="sharingsetting" :data="this.$route.params.data" />
  </div>
</template>

<script>
import SectionLeft from "../components/SectionLeft.vue";
import SharingSection from "../components/sharingsetting/new/SharingSection.vue";
import DataserviceLogo from "../components/DataserviceLogo.vue";
import NavMenuSecondary from "../components/navigationmenu/secondary/NavMenuSecondary.vue"

export default {
  components: {
    SectionLeft,
    SharingSection,
    DataserviceLogo,
    NavMenuSecondary
  },
};
</script>
