<template>
  <div
    class="relative w-full px-4 py-3 my-2 border-2 bg-white border-primary rounded-lg flex justify-between"
  >
    <span>{{ setting.name }}</span>
    <RadioOnoffBorder
      :checked="checked"
      :checkAll="false"
      :val="setting"
      @inputVal="inputVal"
    />
  </div>
</template>

<script>
import RadioOnoffBorder from "../forms/RadioOnoffBorder.vue";

export default {
  props: ["setting"],
  components: {
    RadioOnoffBorder,
  },
  data() {
    return {
      sett: [],
      checked: false,
    };
  },
  created() {
    this.checked = this.setting.checked;
  },
  methods: {
    inputVal(val, checked) {
      this.checked = checked;
      this.$emit("settingVal", val.id, checked);
    },
  },
};
</script>

<style>
</style>