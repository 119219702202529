<template>
  <ModalsConfirm :show="sharingModalsComp">
    <div slot="actionQuestion" class="relative">
      <div
        class="absolute top-0 right-0 text-lg font-proximaSemibold cursor-pointer"
        @click="closeModals"
      >
        ✖
      </div>
    </div>
    <div slot="actionText">
      <div>
        <qr-code
          class="py-3 text-white text-center text-sm font-proximaNormal bg-white cursor-pointer mx-auto w-10/12 md:w-6/12 text-primary px-8 mt-8"
          :text="codeQR"
        ></qr-code>
      </div>
      <!--    <img class="mx-auto" src="@/assets/images/barcode-lg.png" alt="barcode-lg" /> -->
      <!--  <p>{{ codeQR }}</p> -->
      <h5 class="mt-4 font-proximaMedium text-2xl">
        {{ $t("barcode_scan_qr_code") }}
      </h5>

      <!--  <BorderedButton
        class="mx-auto"
        :title="$t('barcode_scan_qr_code')"
        borderColor="border-primary"
        textCl="text-primary"
        paddingX="px-8"
        width="w-10/12 md:w-6/12"
      /> -->
    </div>
    <div slot="leftBtn" />
    <div slot="rightBtn" />
  </ModalsConfirm>
</template>

<script>
import ModalsConfirm from "../modals/ModalsConfirm.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";

//import QRCode from '@keeex/qrcodejs-kx'

export default {
  props: ["codeQR"],
  components: {
    ModalsConfirm,
    SubmitButton,
    BorderedButton,
  },
  data() {
    return {};
  },
  computed: {
    sharingModalsComp() {
      return this.$store.state.sharingBarcodeModalsState;
    },
  },
  created() {
    console.log("////", this.codeQR);
  },
  methods: {
    closeModals() {
      this.$store.commit("sharingBarcodeModals", false);
    },
  },
};
</script>

<style>
</style>